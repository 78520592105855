import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { Edit2, Eye, Maximize4, SearchNormal1, Trash } from "iconsax-react";
import ModalLeft from "../component.js/ModalLeft";
import Moment from "moment";
import { enqueueSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";
import api from "../api";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";


const Products = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);
  const [applicantDetails, setApplicantDetails] = useState(null);
  const [isEditModal, setIsEditModal] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isCreateProduct, setIsCreateProduct] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");

  const [formValue, setFormValue] = useState({
    name: "",
    price: "",
  });

  const handleOpenDelete = (id) => {
    setIsDeleteOpen(!isDeleteOpen);
    setDeleteId(id);
  };
  const HandleDeleteModalClose = () => {
    setIsDeleteOpen(false);
  };
  const closeViewModal = () => {
    setIsViewModal(false);
  };
  const closeEditModal = () => {
    clearForm();
    setIsEditModal(false);
  };

  const closeCreateProduct = () => {
    setIsCreateProduct(false);
  };

  function formatDate(datetimeStr) {
    const date = Moment(datetimeStr);
    const formattedDate = date.format("MMM DD, YYYY");

    return formattedDate;
  }

  async function getProducts(page) {
    const response = await api.getProducts({ params: { page } });
    return response;
  }

  const ProductQuery = useQuery(["users"], () => getProducts(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const createProduct = async () => {
    setIsLoading(true);

    try {
      const response = await api.createProduct({
        name: formValue?.name,
        price: formValue?.price,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      ProductQuery.refetch();
      closeCreateProduct()
    } catch (e) {
      enqueueSnackbar("error creating product", { variant: "error" });
      //console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  };
  const updateProduct = async () => {
    setIsLoading(true);

    try {
      const response = await api.updateProduct(editId?.product_id, {
        name: formValue?.name,
        price: formValue?.price,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      ProductQuery.refetch();
      setIsEditModal(false);
    } catch (e) {
      enqueueSnackbar("error creating product", { variant: "error" });
      //console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  };

  const deleteProduct = async () => {
    setIsLoading(true);

    try {
      const response = await api.deleteProduct(deleteId);

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      ProductQuery.refetch();
      setIsDeleteOpen(false);
    } catch (e) {
      enqueueSnackbar("error deleting product", { variant: "error" });
      setIsLoading(false);
    }
  };

  function clearForm() {
    setFormValue({
      middlename: "",
      lastname: "",
      firstname: "",
      username: "",
      phone_no: "",
      email: "",
      LASSRA: "",
      NIN: "",
      dob: "",
    });
  }

  const handleEditModal = (item) => {
    setFormValue({
      name: item?.name,
      price: item?.price,
      id: item?._id,
    });
    setIsEditModal(true);
  };
  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          All Created Product
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          These is the list of all productts
        </p>
      </div>

      {/* <div className="w-full justify-between flex items-center">
      {" "}
      <button
        onClick={() =>
          toggleFilter()
        }
        type="button"
        className="px-[14px] md:px-[24px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#d6a255]  flex items-center justify-center mb-[20px] md:mb-[32px]"
      >
        <p className="text-sm font-medium leading-[20px]">Filter</p>
      </button>
    </div> */}

      <div className="flex items-center justify-between mb-[20px] md:mb-[24px] ">
        <button
          onClick={() => setIsCreateProduct(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">
            Create New Product
          </p>{" "}
        </button>

        <button
          // onClick={() => setIsCreateAdminModal(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Export</p>{" "}
        </button>

        <Modal
          isCentered
          isOpen={isCreateProduct}
          onClose={closeCreateProduct}
          size="xl"
          style={{ borderRadius: 12 }}
          motionPreset="slideInBottom"
          className="rounded-[12px]"
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader
              py="4"
              color="#000000"
              className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
            >
              Create Product
            </ModalHeader>
            <ModalCloseButton size={"sm"} />
            <Divider color="#98A2B3" />
            <ModalBody
              pt={{ base: "20px", md: "24px" }}
              px={{ base: "16px", md: "24px" }}
              pb={{ base: "30px", md: "40px" }}
              className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
            >
              <div className="mb-[18px]">
                <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                  Product Name
                </label>
                <div className=" relative    flex banks-center">
                  <input
                    type="text"
                    placeholder=""
                    className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                    name="name"
                    id="full-name"
                    value={formValue.pnameice}
                    onChange={(e) => handleInputChange(e)}
                    autoCapitalize="off"
                    autoCorrect="off"
                    spellCheck="false"
                  />
                </div>
              </div>
              <div className="mb-[18px]">
                <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                  Price(#)
                </label>
                <div className=" relative    flex banks-center">
                  <input
                    type="text"
                    placeholder=""
                    className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                    name="price"
                    id="full-name"
                    value={formValue.price}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              </div>
            </ModalBody>
            <Divider />
            <ModalFooter gap={"16px"}>
              <button
                onClick={closeCreateProduct}
                className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
              >
                Cancel
              </button>
              <button
                onClick={createProduct}
                className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex banks-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
              >
                {isLoading ? (
                  <ClipLoader color={"white"} size={20} />
                ) : (
                  <> Upload </>
                )}
              </button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>

      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-5 md:mt-9">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            All Products
          </p>
          <div className=" relative  mt-[16px]  flex items-center">
            <SearchNormal1 size="16" className="absolute left-[4px]" />

            <input
              type="text"
              placeholder="Search by Product name.."
              className="w-[270px] h-[40px] pl-[36px] py-[12px] text-[14px] text-[#344054] bg-[#F9FAFB] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name=""
              id=""
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
                    Product ID
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
                    Product Name
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
                    Price
                  </div>
                </th>

                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex gap-[6px] md:gap-[12px] items-center my-0">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {!isLoading && ProductQuery?.data?.data?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="6">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Product Found
                    </h3>
                  </td>
                </tr>
              )}
              {ProductQuery?.data &&
                ProductQuery?.data?.data?.map((result) => (
                  <tr className="mb-2 hover:bg-light-gray">
                    {/* <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {result?._id}
                  </td> */}
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.product_id}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.name}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    
                    
                    <NumericFormat
                    value={result?.price}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    // renderText={(value) => (
                    //   <p className="text-[#fff]  font-semibold font-i_medium text-[16px] leading-[19px]  tracking-[0.2px]   ">
                    //     {value}
                    //   </p>
                    // )}
                  />  
                    </td>

                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      <button
                        onClick={() => {
                          handleEditModal(result);
                          setEditId(result);
                        }}
                      >
                        <Edit2 variant="Linear" color="#667185" size="20" />
                      </button>
                      <button
                        onClick={() => {
                          handleOpenDelete(result?.product_id);
                        }}
                      >
                        <Trash variant="Linear" color="#C90808FF" size="20" />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <button
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>

        <button
          disabled={ProductQuery.length < 10}
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>
      <Modal
        isCentered
        isOpen={isEditModal}
        onClose={closeEditModal}
        size="xl"
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
          >
            Update Product
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <Divider color="#98A2B3" />
          <ModalBody
            pt={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            pb={{ base: "30px", md: "40px" }}
            className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <div className="mb-[18px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                Product Name
              </label>
              <div className=" relative    flex banks-center">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                  name="name"
                  id="full-name"
                  value={formValue.name}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>
            <div className="mb-[18px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                Price(#)
              </label>
              <div className=" relative    flex banks-center">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                  name="price"
                  id="full-name"
                  value={formValue.price}
                  onChange={(e) => handleInputChange(e)}
                />
              </div>
            </div>
          </ModalBody>
          <Divider />
          <ModalFooter gap={"16px"}>
            <button
              onClick={closeEditModal}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
              onClick={updateProduct}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex banks-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Update </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isCentered
        isOpen={isDeleteOpen}
        onClose={HandleDeleteModalClose}
        size="md"
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px]   font-medium leading-[24px] md:leading-[24px]"
          >
           <Trash color="#C90808FF" size={36} className="text-center mx-auto"/>
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <ModalBody
            py={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            className=" px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <p className=" text-[16px] md:text-lg text-center  text-[#000] leading-[24px] font-medium  ">
              Delete Product
            </p>

            <p className="text-[14px]  text-[#667185] leading-[20px] font-normal text-center mt-2  ">
              Are you sure you want to delete this product? This action cannot
              be undone.
            </p>
          </ModalBody>
          <ModalFooter gap={"16px"}>
            <button
              onClick={HandleDeleteModalClose}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
            onClick={deleteProduct}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex items-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Delete </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalLeft isOpen={isViewModal} onClose={closeViewModal}>
        <div>
          <div className="border-b border-b-[#E4E7EC] p-[16px] md:p-[20px]  md:flex justify-between items-center ">
            <div className="flex items-center gap-[16px]">
              <Maximize4 variant="Linear" color="#667185" size="16" />{" "}
              <div className="h-[32px] w-[1px] bg-[#D0D5DD]" />
              <div className="flex items-center">
                <p className="text-[#667185] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                  User Details
                </p>
              </div>
            </div>
          </div>

          <div class="relative w-[60px] h-[60px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mx-auto mt-3">
            <svg
              class="absolute w-[68px] h-[68px] text-gray-400 -left-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>

          <table className="mt-[18px] md:mt-[24px] max-w-[490px] mx-[16px] md:mx-[20px]  ">
            <tr className="">
              <th className="text-[14px] pb-[20px] text-[#667185] leading-[20px] font-medium text-left ">
                Full Name
              </th>
              <td className="pb-[20px] pl-4 md:pl-6 ">
                {applicantDetails?._fields?.firstname}
              </td>
            </tr>

            <tr>
              <th className="pb-5 text-[14px] text-[#667185] leading-[20px] font-medium text-left ">
                Studentship
              </th>
              <td className="pb-[20px] pl-4 md:pl-6 ">
                <button
                  className={`rounded-[20px] md:rounded-[40px] flex justify-center items-center gap-2 px-[12px]  py-[4px] md:py-[4px] border-[0.5px] ${
                    applicantDetails?._academic_criteria?.studentship_status ===
                    "Unconfirmed"
                      ? "bg-[#FEECEB] text-[#F44336] border-[#F44336]"
                      : // : c.mode === "Medium"
                        // ? "bg-[#FFF5E6] text-[#F44336] border-[#FF9800]"
                        "bg-[#EDF7EE] text-[#4CAF50] border-[#4CAF50]"
                  }  text-[12px] md:text-[14px]  font-semibold leading-[16px] md:leading-[18px] `}
                >
                  <p>
                    {" "}
                    {applicantDetails?._academic_criteria?.studentship_status}
                  </p>
                </button>{" "}
              </td>
            </tr>
          </table>
        </div>
      </ModalLeft>
    </div>
  );
};

export default Products;
