import React, { useEffect, useState } from "react";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import Topbar from "./global/Topbar";
import Sidebar from "./global/Sidebar";
import { MerchantProvider } from "./utils/MerchantContext";
// import jwtDecode from "jwt-decode";

const Home = () => {
  const navigate = useNavigate();
  const [isSidebar, setIsSidebar] = useState(true);
  let location = useLocation();

  let userData = localStorage.getItem("auth");
  // let stringData = JSON.parse(userData);
  //console.log(userData);
  if (!userData) {
    return <Navigate to="/login" />;
  } else {
    //console.log("Valid token");
  }


  


  const handleSideBarClose = () => {
    setIsSidebar(false);
  };
  const toggleSidebar = () => {
    setIsSidebar(!isSidebar);
  };
  return (

    <MerchantProvider>
    <div id="popup-root" className="app bg-[#ffffff] flex ">
      <Sidebar isSidebarOpen={isSidebar} onClose={handleSideBarClose} userData={userData} />
      <main className="bg-[white] w-full overflow-x-hidden">
        <Topbar setIsSidebar={toggleSidebar} userData={userData} />
        <Outlet context={userData} />
      </main>
    </div>
    </MerchantProvider>
  );
};

export default Home;
