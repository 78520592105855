import {
  ArrangeHorizontalSquare,
  ArrowRight,
  ArrowRight2,
  Building,
  Chart,
  ChartFail,
  ChartSuccess,
  EmptyWalletTime,
  More,
  TransmitSquare,
} from "iconsax-react";
import React, { useEffect, useState } from "react";
import Moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import api from "../../api";
import { NumericFormat } from "react-number-format";

const CustomerDashboard = () => {
  const [timeOfDay, setTimeOfDay] = useState("");
  const [page, setPage] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  let userData = localStorage.getItem("auth");

  userData = JSON.parse(userData);

  useEffect(() => {
    const getCurrentTimeOfDay = () => {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        return "Morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        return "Afternoon";
      } else {
        return "Evening";
      }
    };

    setTimeOfDay(getCurrentTimeOfDay());
  }, []);
  function formatDate(datetimeStr) {
    const date = Moment(datetimeStr);
    const formattedDate = date.format("MMM DD, YYYY");
    return formattedDate;
  }

  async function getDashboard(page) {
    const response = await api.dashboard();
    return response;
  }

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  const DashboardQuery = useQuery(["dashboard"], () => getDashboard(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const dashData = DashboardQuery?.data || "";
  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";
  async function getTransactions(page) {
    const response = await api.getTransaction({ params: { page } });
    return response;
  }

  const TransactionQuery = useQuery(["transaction"], () => getTransactions(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const TrasactionData = TransactionQuery?.data?.data || [];


  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[20px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          Good {timeOfDay}, {profileData?.name}
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          These are your Stats
        </p>
      </div>
      <ul className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[16px] md:gap-[20px] mt-5 ">
        <li className="rounded-lg relative z-10 bg-slate-50 overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Total Balance
            </p>
            <button className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <EmptyWalletTime size="18" color="#124072" />
            </button>
          </div>
          <div className="flex mt-6">
            <div className=" border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                <NumericFormat
                  value={profileData?.balance?.balance}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₦"}
                  decimalScale={2}
                  fixedDecimalScale={true}
                />
              </p>
            </div>
            <Link
              to="/wallet"
              className={`rounded-[20px] mt-6 flex justify-center items-center gap-2 px-[12px]  py-[4px] md:py-[4px] border-[0.5px]
                           bg-[#EDF7EE] text-[#4CAF50] border-[#4CAF50] text-[10px] md:text-[12px]  font-semibold leading-[16px] md:leading-[18px] `}
            >
              <p>View Wallet </p> <ArrowRight2 size={16} />
            </Link>{" "}
          </div>
        </li>
        <li className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Success Transactions
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
          <ChartSuccess size="34" color="green" />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                {dashData?.successful_tranx}
              </p>
            </div>
          </div>
        </li>
        <li className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Failed Transaction
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
            <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
          <ChartFail size="34" color="red" />

            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                {dashData?.failed}
              </p>
            </div>
          </div>
        </li>

        <li className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
              Total Item Purchased
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
           <ArrangeHorizontalSquare size="34" color="#124072" />


            <div className="border-r-[0.8px] border-[#E4E7EC] pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                {dashData?.total_items_purchased}
              </p>
            </div>
          </div>
        </li>

        <li className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] shadow p-2 md:p-4">
          <div className="flex justify-between">
            <p className="text-[14px] md:text-[16px] text-black font-meddium ">
              {" "}
            Current Business 
            </p>
            <div className="text-red px-2 py-1 rounded-md border bg-gray-200 self-end">
              <TransmitSquare size="18" color="#124072" />
            </div>
          </div>
          <div className="flex items-center gap-3 mt-6 justify-center">
          <Building size="34" color="#124072" />


            <div className=" pr-2">
              {" "}
              <p className="text-[#000] font-semibold text-[20px] md:text-[32px]  ">
                {profileData?.balance?.merchant_info?.business_name}
              </p>
            </div>
          </div>
        </li>
      </ul>

      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-5 md:mt-9">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            Recent Transaction
          </p>
          <Link to="/transaction">
            <button className="flex items-center gap-2">
              {" "}
              <p className=" text-[14px] md:text-base  text-[#124072] leading-[24px] font-medium text-left ">
                View Transactions
              </p>
              <ArrowRight size="16" color="#124072" />
            </button>
          </Link>
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Date
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Transaction ID
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Description
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap  gap-[6px] md:gap-[12px] items-center my-0">
                    Charge
                  </div>
                </th>
                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    Amount
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {isLoading && TrasactionData?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="6">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Transaction Found
                    </h3>
                  </td>
                </tr>
              )}
              {TrasactionData &&
                TrasactionData?.slice(0,5)?.map((result) => (
                  <tr className="mb-2 hover:bg-light-gray">
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.created_at}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.product_id}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.description}
                    </td>
                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {result?.platform_charges}
                    </td>

                    <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      <NumericFormat
                        value={result?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />

                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
    
    
      </div>
    </div>
  );
};

export default CustomerDashboard;
