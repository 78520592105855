import {
  CHANGEMERCHANTSTATUS,
  CREATECLIENT,
  CREATEPRODUCT,

 CREATESUBMERCHANT,

 DELETEPRODUCT,
 GETCLIENT,
 GETPRODUCT,
 
 GETSUBMERCHANT,
 
 UPDATEPRODUCT,
 SYNCPRODUCT,
 SYNCMERCHANTTODEPARTMENT,
 DELETEDDEPARTMENT,
 CREATEDEPARTMENT,
 GETDEPARTMENT,
 GETMYMERCHANTS,
 GETMERCHANTPRODUCT,
 PAYMENTCALLBACK,
 INITIALIZEPAYMENT,
 MANAGEPASSWORD,
 UPDATEIMAGE,
 UPDATENOTIFICATION,
 CREATEPIN,
 MYPROFILE,
 TRANSFER,
 MAKEPAYMENT,
 DASHBOARD,
 GETPROFILE,
 SWITCHMERCHANT,
 UPDATEPIN,
 GETTRANSACTION,
 PURCHASES,
 GETPURCHASETOKEN,
 UPDATEPURCHASETOKEN,
 CREDITCUSTOMERWALLET,
 DEBITCUSTOMERWALLET,
 CUSTOMERPURCHASES,
 ALLMERCHANTS,
 UPDATEPROFILE,

} from "../utils/config";
import { apiDelete, apiGet, apiGetCSV, apiPost, apiPut } from "../utils/utils";

//clients
export function createClient(data) {
  return apiPost(CREATECLIENT, data);
}

export function changeStatus(data) {
  return apiPut(CHANGEMERCHANTSTATUS + data);
}

export function getClients(data) {
  return apiGet(GETCLIENT, data);
}

//SUB Merchants
export function getSubmerchants(data) {
  return apiGet(GETSUBMERCHANT, data);
}
export function createSubmerchants(data) {
  return apiPost(CREATESUBMERCHANT, data);
}

export function getMyMerchants(data) {
  return apiGet(GETMYMERCHANTS, data);
}

export function getMerchantProduct(data) {
  return apiGet(GETMERCHANTPRODUCT+ data);
}
//callBack
export function paymentCallback(data) {
  return apiPost(PAYMENTCALLBACK, data);
}

// Products

export function getProducts(data) {
  return apiGet(GETPRODUCT, data);
}

export function createProduct(data) {
  return apiPost(CREATEPRODUCT, data);
}
export function updateProduct(id,data) {
  return apiPut(UPDATEPRODUCT + id, data);
}
export function deleteProduct(data) {
  return apiDelete(DELETEPRODUCT + data);
}

// Dept
export function getDepartments(data) {
  return apiGet(GETDEPARTMENT, data);
}

export function createDepartment(data) {
  return apiPost(CREATEDEPARTMENT, data);
}

export function deleteDepartment(data) {
  return apiDelete(DELETEDDEPARTMENT + data);
}

// Sync
export function syncProductToDept(data) {
  return apiPost(SYNCPRODUCT, data);
}

export function syncMerchantToDept(data) {
  return apiPost(SYNCMERCHANTTODEPARTMENT, data);
}

//payment
export function initiatePayment(data) {
  return apiPost(INITIALIZEPAYMENT, data);
}

//make payment
export function makePayment(data) {
  return apiPost(MAKEPAYMENT, data);
}

//pin
export function managePassword(data) {
  return apiPost(MANAGEPASSWORD, data);
}

export function updateImage(data) {
  return apiPost(UPDATEIMAGE, data,  { 'Content-Type': 'multipart/form-data' });
}

export function upateNotification(data) {
  return apiPut(UPDATENOTIFICATION, data);
}
export function updatePin(data) {
  return apiPost(CREATEPIN, data);
}

export function updatePassword(data) {
  return apiPost(MANAGEPASSWORD, data);
}
//profile
export function getProfile(data) {
  return apiGet(MYPROFILE, data);
}

export function transfer(data) {
  return apiPost(TRANSFER, data);
}

//dashboard

export function dashboard(data) {
  return apiGet(DASHBOARD, data);
}

//switch merchant
export function switchMerchant(data) {
  return apiPost(SWITCHMERCHANT, data);
}

//update pin

export function updateUserPin(data) {
  return apiPost(UPDATEPIN, data);
}

// get transaction

export function getTransaction(data) {
  return apiGet(GETTRANSACTION, data);
}

// purchases
export function getPurchases(data) {
  return apiGet(PURCHASES, data);
}

//purchase token
export function checkPurchaseToken(data) {
  return apiGet(GETPURCHASETOKEN + data);
}

export function changePurchaseTokenStatus(data) {
  return apiPut(UPDATEPURCHASETOKEN + data);
}

//credit and debit wallet

export function creditCustomerWallet(data) {
  return apiPost(CREDITCUSTOMERWALLET, data);
}

export function debitCustomerWallet(data) {
  return apiPost(DEBITCUSTOMERWALLET, data);
}

// customer purchase

export function getCustomerPurchases(data) {
  return apiGet(CUSTOMERPURCHASES, data);
}

// all merchant

export function getAllMerchants(data) {
  return apiGet(ALLMERCHANTS, data);
}

//update profil

export function updateProfile(data) {
  return apiPost(UPDATEPROFILE, data);
}

//csv