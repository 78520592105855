import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import api from "../../api";
import { useQuery } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  Thead,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import PreviewModal from "../../component.js/PreviewPaymentModal";
import CreatePin from "../../component.js/CreatePin";
import { Trash } from "iconsax-react";
import { NumericFormat } from "react-number-format";

const MakePayment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [merchant, setMerchant] = useState(null);
  const [product, setProduct] = useState("");
  const [amount, setAmount] = useState("");
  const [pin, setPin] = useState("");
  const [note, setNote] = useState("");
  const [isPreview, setIsPreview] = useState(false);
  const [id, setId] = useState("");
  const [createPin, setCreatePin] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [invoiceItems, setInvoiceItems] = useState([]);

  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const openPreview = (e) => {
    e.preventDefault();
    setIsPreview(true);
  };
  const closePreview = () => {
    setIsPreview(false);
    clearForm();
  };

  function clearForm() {
    setMerchant("");
    setPin("");
    setAmount("");
    setNote("");
    setProduct("");
  }

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  async function getProduct() {
    const response = await api.getMerchantProduct(
      profileData?.balance?.merchant_id
    );
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  const ProductQuery = useQuery(["products", profileData], () => getProduct(), {
    keepPreviousData: false,
    refetchOnWindowFocus: "always",
  });

  const ProductData = ProductQuery?.data?.data;

  const addItemToInvoice = () => {
    if (!selectedProduct) {
      enqueueSnackbar("Please select a product before adding to the invoice", {
        variant: "warning",
      });
      return;
    }
    const selectedProductDetails = ProductData.find(
      (product) => product.product_id === parseInt(selectedProduct)
    );
    if (!selectedProductDetails) {
      enqueueSnackbar("Failed to fetch product details", { variant: "error" });
      return;
    }
    const newItem = {
      id: selectedProductDetails.product_id,
      name: selectedProductDetails.name,
      quantity: 1,
      price: parseFloat(selectedProductDetails.price),
      amount: parseFloat(selectedProductDetails.price) * 1,
    };
    setInvoiceItems((prevItems) => [...prevItems, newItem]);

    setSelectedProduct("");
  };

  const onDeleteItemHandler = (index) => {
    // if (invoiceItems.length <= 1) {
    //   return;
    // }
    let allItem = [...invoiceItems];
    let eachItem = allItem[index];
    let amount = eachItem.amnt;
    // setSubtotal((prev) => prev - amount);
    allItem.splice(index, 1);
    setInvoiceItems(allItem);
  };

  const calculateSubtotal = () => {
    return invoiceItems.reduce((total, item) => total + item.amount, 0);
  };

  const calculateTotal = () => {
    return calculateSubtotal();
  };

  const totalAmount = calculateTotal();

  async function handlePayment(e) {
    e.preventDefault();
    setIsConfirmLoading(true);

    try {
      const response = await api.makePayment({
        //department_id: product?.department[0]?.id || null,
        merchant_id: profileData?.balance?.merchant_id,
        products: invoiceItems.map((item) => {
          return {
            product_id: item.id,
            product_name: item.name,
            
          };
        }),
        amount: totalAmount,
        is_invoice: false,

        pin,
        note,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsConfirmLoading(false);
      closePreview();
    } catch (e) {
      setIsConfirmLoading(false);

      enqueueSnackbar(e.message[0], { variant: "error" });
    }
  }

  const handleProductChange = (event) => {
    const productId = parseInt(event.target.value);
    const product = ProductData.find((p) => p.product_id === productId);
    setProduct(product);
  };

  useEffect(() => {
    let userData = localStorage.getItem("auth");
    let stringData = JSON.parse(userData);

    if (!stringData?.user?.has_pin) {
      setCreatePin(true);
    }
  }, []);

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <Modal
        isCentered
        isOpen={isPreview}
        onClose={closePreview}
        size={{ base: "xs", sm: "md", lg: "xl" }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <PreviewModal
            isLoading={isConfirmLoading}
            handlePayment={handlePayment}
            onClose={closePreview}
            merchant={profileData?.balance?.merchant_info?.business_name}
            amount={totalAmount}
            product={product?.name}
            note={note}
            invoiceItems={invoiceItems}
          />
        </ModalContent>
      </Modal>

      {!profileData?.has_pin && (
        <CreatePin isCreatePin={createPin} setIsCreatePin={setCreatePin} />
      )}
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          Make a Payment
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          Pay for goods or services{" "}
        </p>
      </div>

      <form onSubmit={openPreview}>
        <div className="mb-[24px]">
          <div className="mb-[24px]">
            <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
              Merchant{" "}
            </label>
            <div className=" ">
              <input
                type="text"
                placeholder=""
                className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                required
                autoComplete="on"
                disabled
                name="amount"
                value={profileData?.balance?.merchant_info?.business_name}
                autoCapitalize="off"
                autoCorrect="off"
                spellCheck="false"
              />
            </div>
          </div>
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Choose Product or Services
          </label>
          <div className=" flex items-center gap-5">
            <select
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name="name"
              onChange={(e) => setSelectedProduct(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            >
              <option value={""}>Select Product/Service</option>
              {ProductData &&
                ProductData.map((product, index) => (
                  <option key={index} value={product?.product_id}>
                    {product.name}
                    {"--"} {`${"₦"} ${product.price}`}
                  </option>
                ))}
            </select>

            <button
              type="button"
              onClick={addItemToInvoice}
              className="py-[7px] md:py-[10px] text-[10px] md:text-[14px] text-center whitespace-nowrap text-white bg-[#124072] rounded-lg px-3 md:px-5"
            >
              Add Item
            </button>
          </div>
        </div>

        {/* ////////// */}
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-[#F9FAFB]">
              <tr className="">
                <th
                  scope="col"
                  className="  border-b-[0.8px] border-[#E4E7EC] py-[12px] px-3 md:px-5   text-[13px] md:text-[16px] text-[#98A2B3]  font-medium  tracking-[0.2%]"
                >
                  <div className="flex   whitespace-nowrap  items-center">
                    ITEM
                  </div>
                </th>
                <th
                  scope="col"
                  className="  border-b-[0.8px] border-[#E4E7EC] py-[12px] px-3 md:px-5   text-[13px] md:text-[16px] text-[#98A2B3]  font-medium  tracking-[0.2%]"
                >
                  <div className="flex px-5 whitespace-nowrap  gap-[6px] md:gap-[12px] items-center">
                    QTY
                  </div>
                </th>

                <th
                  scope="col"
                  className="  border-b-[0.8px] border-[#E4E7EC] py-[12px] px-3 md:px-5   text-[13px] md:text-[16px] text-[#98A2B3]  font-medium  tracking-[0.2%]"
                >
                  <div className="flex whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    PRICE ₦
                  </div>
                </th>
                <th
                  scope="col"
                  className="  border-b-[0.8px] border-[#E4E7EC] py-[12px] px-3 md:px-5   text-[13px] md:text-[16px] text-[#98A2B3]  font-medium  tracking-[0.2%]"
                >
                  <div className="flex  whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    AMOUNT ₦
                  </div>
                </th>
                <th
                  scope="col"
                  className="  border-b-[0.8px] border-[#E4E7EC] py-[12px] px-3 md:px-5   text-[13px] md:text-[16px] text-[#98A2B3]  font-medium  tracking-[0.2%]"
                >
                  <div className="flex  whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    ACTION
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((item, index) => (
                <tr key="_" className="mb-2 hover:bg-light-gray">
                  <td className="whitespace-nowrap py-[12px] md:py-[16px] bg-white px-3 md:px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px]  tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {item.name}
                  </td>
                  <td className="whitespace-nowrap py-[16px] bg-white   border-b-[0.8px] border-[#E4E7EC] text-[14px]  tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <input
                      className="w-[60px] focus:outline-none mx-auto border px-1 rounded-sm "
                      value={item.quantity}
                      onChange={(e) => {
                        const newQuantity = parseInt(e.target.value, 10) || 0;
                        const updatedItems = [...invoiceItems];
                        updatedItems[index].quantity = newQuantity;
                        updatedItems[index].amount = newQuantity * item.price;
                        setInvoiceItems(updatedItems);
                      }}
                    />
                  </td>
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <NumericFormat
                      value={item.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={(value) => <p>{value}</p>}
                    />{" "}
                  </td>
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <NumericFormat
                      value={item.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"₦"}
                      decimalScale={2}
                      fixedDecimalScale={true}
                      renderText={(value) => <p>{value}</p>}
                    />
                  </td>
                  <td className=" py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <button onClick={() => onDeleteItemHandler(index)}>
                      <Trash
                        color="orange"
                        className="text-[20px]  hover:text-red-500 "
                      />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="flex justify-end mb-[24px]">
          <div className="flex items-center py-2 border-t w-[40%] md:w-[30%] lg:w-[20%] px-4 gap-1">
            <p>Total:</p>
            <p>₦{calculateTotal()}</p>
          </div>
        </div>

        {/* <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Enter Amount
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              disabled
              name="amount"
              value={product?.price}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div> */}
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Enter Pin
          </label>
          <div className=" ">
            <input
              type="text"
              placeholder=""
              className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              name="pin"
              value={pin}
              onChange={(e) => setPin(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div className="mb-[24px]">
          <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
            Note
          </label>
          <div className=" ">
            <textarea
              type="text"
              placeholder=""
              className="w-full h-[120px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              name="note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>

        <button
          type="submit"
          className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Make Payment</p>{" "}
          {isLoading && (
            <ClipLoader color={"white"} size={20} className="ml-2" />
          )}
        </button>
      </form>
    </div>
  );
};

export default MakePayment;
