import { Logout } from "iconsax-react";
import React, { useContext } from "react";

import { Link } from "react-router-dom";
import { clearUserData } from "../utils/utils";
import CustomerSideBar from "../component.js/CustomerSideBar";
import MerchantsSideBar from "../component.js/MerchantsSideBar";
import { useMerchant } from "../utils/MerchantContext";
import { BASE_URL } from "../utils/config";

const Sidebar = ({ isSidebarOpen, onClose, userData }) => {
  let role = JSON.parse(userData);
  const currentMerchant = role?.user?.balance?.merchant_info?.id;
  const merchantLogo = role?.user?.image

  role = role?.user?.role;

  const { merchantData } = useMerchant();
  const currentMerchantLogo = merchantData && merchantData
    ?.filter((item) => parseFloat(currentMerchant) === item.merchant_id)
    .map((item) => {
      return item?.user?.image;
    });

  // console.log("theis is context=====>>>>", currentMerchantLogo[0]);

  return (
    <div
      className={` lg:block lg:relative ${
        isSidebarOpen ? "block z-20 fixed inset-0 transition-opacity" : "hidden"
      }`}
    >
      <div
        onClick={onClose}
        className="fixed inset-0 bg-[#29292980]  transition-opacity lg:relative"
      ></div>
      <div class="absolute top-0 right-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
        <button
          onClick={onClose}
          type="button"
          class="rounded-md text-gray-700 hover:text-[white] focus:outline-none focus:ring-2 focus:ring-[white]"
        >
          <span class="sr-only">Close panel</span>

          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="w-[200px] md:w-[272px]  min-h-screen bg-[#124072] border-r  pb-7 sticky top-0  ">
        <div className="flex gap-[16px] bg-[#fefefe] pb-[20px] items-center px-[16px] md:px-[26px] mb-[32px] md:mb-[48px] ">
          {role === "customer" && currentMerchantLogo ? (
            <img
              src={`
             ${BASE_URL}/storage/${currentMerchantLogo}`}
              alt="logo"
              class="mx-auto mt-3 h-[40px] "
            />
          ) : (role === "merchant" || role === "sub-merchant") &&
            merchantData ? (
            <img
            src={`
              ${BASE_URL}${merchantLogo}`}   
              alt="logo"
              class="mx-auto mt-3 h-[40px] "
            />
          ) : (
            <img
              src="./asset/paylodeLogo.png"
              alt="logo"
              class="mx-auto mt-3 h-[40px] "
            />
          )}
        </div>
        <div className="border-b pb-[32px] mb-[32px] border-[#CCCCF5]">
          {role === "merchant" || role === "sub-merchant" ? (
            <MerchantsSideBar />
          ) : (
            <CustomerSideBar />
          )}
        </div>

        <button
          onClick={clearUserData}
          className="flex gap-2 items-center mr-2"
        >
          <Link to="/login">
            <div
              className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
                window.location.pathname === ""
                  ? "  text-[#002240] bg-[#E9EBFD] "
                  : "text-[#fff] ml-[12px]"
              }`}
            >
              <Logout size="24" />
              <div>Log out</div>
            </div>
          </Link>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
