import {
  ArrowDown2,
  ArrowUp2,
  FolderOpen,
  Layer,
  Note1,
  Notepad2,
  Profile,
  WalletMoney,
} from "iconsax-react";
import React, { useState } from "react";
import { BiGridAlt } from "react-icons/bi";
import { Link } from "react-router-dom";

const CustomerSideBar = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div>
      {" "}
      <Link to="/dashboard" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/dashboard" ? "block" : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px] pl-[17px]  flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/dashboard"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <BiGridAlt className="text-[24px] " />
          <div>Dashboard</div>
        </div>
      </Link>
      <Link to="/transaction" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/transaction" ||
            window.location.pathname === "/transaction"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/transaction"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Note1 size="24" />
          <div>Transaction</div>
        </div>
      </Link>
      <Link to="/purchases" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/purchases" ||
            window.location.pathname === "/purchases"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/purchases"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Notepad2 size="24" />
          <div>Purchases</div>
        </div>
      </Link>
      <Link to="/make-payment" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/make-payment" ||
            window.location.pathname === "/make-payment"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/make-payment"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <FolderOpen size="24" />
          <div>Make Payment</div>
        </div>
      </Link>
      <Link to="/wallet" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/wallet" ||
            window.location.pathname === "/wallet"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/wallet"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <WalletMoney size="24" />
          <div>Wallet</div>
        </div>
      </Link>
      {/* <Link to="/products" className="flex gap-2 items-center mr-2">
        <div
          className={`${
            window.location.pathname === "/products" ||
            window.location.pathname === "/products"
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>
        <div
          className={`w-full   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            window.location.pathname === "/products"
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Layer size="24" />
          <div>Fund Wallet</div>
        </div>
      </Link> */}
      <button
        onClick={() => setIsActive(!isActive)}
        className="flex gap-2 items-center pr-2 w-full"
      >
        <div
          className={`${
            
            (window.location.pathname === "/change-pin" ||
              window.location.pathname === "/profile" ||
              window.location.pathname === "/change-password")
              ? "block"
              : "hidden"
          } h-[32px] bg-[#E9EBFD]  w-[4px] `}
        ></div>

        <div
          className={`w-full flex-1   py-[12px]  pl-[17px] flex tracking-[0.2px] gap-[12px] font-medium text-[14px] leading-[21px] ${
            
            (window.location.pathname === "/change-pin" ||
              window.location.pathname === "/profile" ||
              window.location.pathname === "/change-password")
              ? "  text-[#002240] bg-[#E9EBFD] "
              : "text-[#fff] ml-[12px]"
          }`}
        >
          <Profile size="24" />
          <div>Profile</div>
        </div>

        {isActive ? (
          <ArrowUp2 size="16" variant="Linear" color="#E9EBFD" />
        ) : (
          <ArrowDown2 size="16" variant="Linear" color="#E9EBFD" />
        )}
      </button>
      {isActive && (
        <ul className="ml-[26px] pl-[12px] py-[14px] mb-3 border-l border-[#98A2B3]/50">
             <Link
            to="/profile"
            className={` py-[6px] pl-[12px] flex items-center text-[12px] mr-2  rounded-md  hover:bg-[#F7F9FC] hover:text-[#002240]    leading-[18px] md:leading-[24px]  mb-[14px] ${
              window.location.pathname === "/profile"
                ? "text-[#002240] bg-[#E9EBFD] font-medium "
                : " text-[#fff] font-normal"
            }`}
          >
            <li className="">My Profile</li>
          </Link>
          <Link
            to="/change-pin"
            className={` py-[6px] pl-[12px] flex items-center text-[12px] mr-2  rounded-md  hover:bg-[#F7F9FC] hover:text-[#002240]    leading-[18px] md:leading-[24px]  mb-[14px] ${
              window.location.pathname === "/change-pin"
                ? "text-[#002240] bg-[#E9EBFD] font-medium "
                : " text-[#fff] font-normal"
            }`}
          >
            <li className="">Change/Create Pin</li>
          </Link>

          <Link
            to="/change-password"
            className={` py-[6px] pl-[12px] flex items-center text-[12px] mr-2  text-[#667185] rounded-md  hover:bg-[#F7F9FC] hover:text-[#002240] leading-[18px] md:leading-[24px]  ${
              window.location.pathname === "/change-password"
                ? "text-[#002240] bg-[#E9EBFD] font-medium  "
                 : " text-[#fff] font-normal"
            }`}
          >
            <li className="">Change Password</li>
          </Link>
        </ul>
      )}
    </div>
  );
};

export default CustomerSideBar;
