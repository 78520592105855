//production server
// export const API_BASE_URL = 'http://94.229.79.27:55412/api/v1';

//live server
export const API_BASE_URL = 'https://paylode.devapi.live/v0.1/api';
export const BASE_URL = 'https://paylode.devapi.live';
export const getApiUrl = (endpoint) => API_BASE_URL + endpoint



export const CREATECLIENT = getApiUrl('/merchant/single-upload');
export const CHANGEMERCHANTSTATUS = getApiUrl('/merchant/change-status');
export const GETCLIENT = getApiUrl('/merchant/my-client');
export const GETSUBMERCHANT = getApiUrl('/sub-merchant/all');
export const CREATESUBMERCHANT = getApiUrl('/sub-merchant/create');
export const CREATEPRODUCT = getApiUrl('/merchant/create-product');
export const GETPRODUCT = getApiUrl('/merchant/my-products');
export const UPDATEPRODUCT = getApiUrl('/merchant/update-product/');
export const DELETEPRODUCT = getApiUrl('/merchant/delete-product/');
export const CREATEDEPARTMENT = getApiUrl('/merchant/create-dept');
export const GETDEPARTMENT = getApiUrl('/merchant/my-dept');
export const DELETEDDEPARTMENT = getApiUrl('/merchant/delete-dept/');
export const SYNCPRODUCT = getApiUrl('/merchant/sync-product-to-dept');
export const GETMERCHANTPRODUCT = getApiUrl('/customer/merchant-product/');
export const GETMYMERCHANTS = getApiUrl('/customer/my-merchant');
export const SYNCMERCHANTTODEPARTMENT = getApiUrl('/merchant/sync-merchant-to-dept');
export const PAYMENTCALLBACK = getApiUrl('/paylode/callback');
export const INITIALIZEPAYMENT = getApiUrl('/paylode/initiate');
export const MANAGEPASSWORD = getApiUrl('/settings/manage-password');
export const UPDATEPIN = getApiUrl('/settings/update-pin');
export const UPDATEIMAGE = getApiUrl('/settings/update-profile-image');
export const UPDATENOTIFICATION = getApiUrl('/settings/update-notification-settings');
export const MYPROFILE = getApiUrl('/user');
export const CREATEPIN = getApiUrl('/settings/pin');
export const TRANSFER = getApiUrl('/paylode/transfer');
export const MAKEPAYMENT = getApiUrl('/paylode/payment-services');
export const DASHBOARD = getApiUrl('/customer/index');
export const SWITCHMERCHANT = getApiUrl('/customer/switch-merchant');
export const GETTRANSACTION = getApiUrl('/paylode/tranx-history');
export const PURCHASES = getApiUrl('/customer/purchased-items');
export const GETPURCHASETOKEN = getApiUrl('/paylode/check-purchase-token/');
export const UPDATEPURCHASETOKEN = getApiUrl('/paylode/change-token-status/');
export const CREDITCUSTOMERWALLET = getApiUrl('/paylode/credit-customer-wallet');
export const DEBITCUSTOMERWALLET = getApiUrl('/paylode/debit-customer-wallet');
export const CUSTOMERPURCHASES = getApiUrl('/merchant/customer-purchased-items');
export const ALLMERCHANTS = getApiUrl('/paylode/all-merchant');
export const UPDATEPROFILE = getApiUrl('/settings/update-profile');






