import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import api from "../api";
import { useQuery } from "@tanstack/react-query";

// Create the context
const MerchantContext = createContext();

// Create a provider component
export const MerchantProvider = ({ children }) => {
  

//   useEffect(() => {
//     // Fetch user role from API
//     const fetchRole = async () => {
//       try {
//         const response = await axios.get("/api/user/role"); // Replace with your API endpoint
//         setRole(response.data.role);
//       } catch (err) {
//         setError("Failed to fetch user role");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchRole();
//   }, []);
  async function getMerchants(page) {
    const response = await api.getMyMerchants({ params: { page } });
    return response;
  }


    const MerchantsQuery = useQuery(["merchants"], () => getMerchants(), {
      keepPreviousData: true,
      refetchOnWindowFocus: "always",
    });
  
    const merchantData = MerchantsQuery?.data?.data || "";

  // Provide role, loading, and error states
  return (
    <MerchantContext.Provider value={{ merchantData }}>
      {children}
    </MerchantContext.Provider>
  );
};

// Custom hook for accessing the RoleContext
export const useMerchant = () => {
  const context = useContext(MerchantContext);
  if (!context) {
    throw new Error("useMerchant must be used within a MerchantProvider");
  }
  return context;
};
