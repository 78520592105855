import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Edit2,
  Eye,
  Link,
  Maximize4,
  More,
  SearchNormal1,
  Trash,
} from "iconsax-react";
import ModalLeft from "../component.js/ModalLeft";
import Moment from "moment";
import { enqueueSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";
import api from "../api";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

const Department = () => {
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateDepartment, setIsCreateDepartment] = useState(false);
  const [isViewModal, setIsViewModal] = useState(false);

  const [isEditModal, setIsEditModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isSyncProduct, setIsSyncProduct] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [department, setDepartment] = useState("");
  const [selectedItems, setSelectedItems] = useState([]); // Selected items
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleOpenDelete = (department) => {
    setIsDeleteOpen(!isDeleteOpen);
    setDepartment(department);
  };
  const HandleDeleteModalClose = () => {
    setIsDeleteOpen(false);
  };

  const handleCreateDepartment = (department) => {
    setIsCreateDepartment(!isCreateDepartment);
    setDepartment(department);
  };

  const closeCreateDepartment = () => {
    setIsCreateDepartment(false);
  };

  const [formValue, setFormValue] = useState({
    name: "",
  });

  const closeViewModal = () => {
    setIsViewModal(false);
  };

  const handleSyncProduct = (department) => {
    setIsSyncProduct(!isSyncProduct);
    setDepartment(department);
  };

  const closeSyncProduct = () => {
    setIsSyncProduct(false);
  };

  function formatDate(datetimeStr) {
    const date = Moment(datetimeStr);
    const formattedDate = date.format("MMM DD, YYYY");

    return formattedDate;
  }

  async function getDepartments(page) {
    const response = await api.getDepartments({ params: { page } });
    return response;
  }

  const DepartmentQuery = useQuery(["users"], () => getDepartments(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item)); // Remove if already selected
    } else {
      setSelectedItems([...selectedItems, item]); // Add if not selected
    }
  };

  const UpdateApplicant = async () => {
    setIsLoading(true);

    try {
    } catch (e) {
      enqueueSnackbar(e?.error?.msg, { variant: "error" });
      console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  };

  function clearForm() {
    setFormValue({
      middlename: "",
    });
  }

  const handleEditModal = (item) => {
    setFormValue({
      firstname: item?.firstname,
    });
    setIsEditModal(true);
  };
  const handleInputChange = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  async function createDepartment(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.createDepartment({
        name: formValue?.name,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      DepartmentQuery.refetch();
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  }

  async function syncProduct(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.syncProductToDept({
        department_id: department?.department_id,
        product_ids: selectedItems,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      DepartmentQuery.refetch();
      setIsSyncProduct(false);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: "error" });
      console.error("Error updating applicant: ", e);
      setIsLoading(false);
    }
  }
  // Toggle item selection
  const toggleSelectItem = (item) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  // Remove selected item
  const removeItem = (item) => {
    setSelectedItems(selectedItems.filter((i) => i !== item));
  };

  async function getProducts(page) {
    const response = await api.getProducts({ params: { page } });
    return response;
  }

  const ProductQuery = useQuery(["product"], () => getProducts(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const deleteDepartment = async () => {
    setIsLoading(true);

    try {
      const response = await api.deleteDepartment(department?.department_id);

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      DepartmentQuery.refetch();
      setIsDeleteOpen(false);
    } catch (e) {
      enqueueSnackbar("error deleting product", { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="p-[16px] md:px-[24px] xl:p-[32px]">
      <div className="mb-[24px] md:mb-[28px] xl:mb-[32px]">
        <h3 className="text-[18px] md:text-[24px] font-meddium text-[#25324B]">
          All Department
        </h3>
        <p className="text-[14px] md:text-[16px] font-meddium text-[#7C8493]">
          {" "}
          These are the list of Department
        </p>
      </div>

      <div className="flex items-center justify-between mb-[20px] md:mb-[24px] ">
        <button
          onClick={() => handleCreateDepartment(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">
            Create New Department
          </p>{" "}
        </button>

        {/* <button
          // onClick={() => setIsCreateAdminModal(true)}
          type="button"
          className="px-[14px] md:px-[20px] py-[10px]  md:py-[14px] text-center text-white bg-[#124072] hover:bg-[#185392]  flex items-center justify-center mb-[20px] md:mb-[32px]"
        >
          <p className="text-sm font-medium leading-[20px]">Export</p>{" "}
        </button> */}
      </div>

      <div className="rounded-lg overflow-hidden border-[0.8px] border-[#E4E7EC] mt-5 md:mt-9">
        <div className="flex items-center justify-between bg-white p-3">
          <p className=" text-[16px] md:text-lg  text-[#000] leading-[24px] font-medium text-left ">
            All Department
          </p>
          <div className=" relative  mt-[16px]  flex items-center">
            <SearchNormal1 size="16" className="absolute left-[4px]" />

            <input
              type="text"
              placeholder="Search by department name.."
              className="w-[270px] h-[40px] pl-[36px] py-[12px] text-[14px] text-[#344054] bg-[#F9FAFB] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
              required
              autoComplete="on"
              name=""
              id=""
              value={searchName}
              onChange={(e) => {
                setSearchName(e.target.value);
              }}
              autoCapitalize="off"
              autoCorrect="off"
              spellCheck="false"
            />
          </div>
        </div>
        <div class="overflow-x-auto rounded-lg">
          <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
            <thead className="bg-light-gray">
              <tr className="">
                <th
                  scope="col"
                  className=" px-2 md:px-5 border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  gap-[6px] md:gap-[12px] items-center my-0">
                    Department Name
                  </div>
                </th>

                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex  whitespace-nowrap gap-[6px] md:gap-[12px] items-center my-0">
                    Date Created
                  </div>
                </th>

                <th
                  scope="col"
                  className="px-2 md:px-5  border-b-[0.8px] border-[#E4E7EC] py-[12px] gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                >
                  <div className="flex gap-[6px] md:gap-[12px] items-center my-0">
                    Action
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <div>Loading...</div>}
              {!isLoading && DepartmentQuery?.data?.data?.length === 0 && (
                <tr>
                  <td className="text-center" colspan="4">
                    <img
                      src="./nodata.gif"
                      className="mx-auto mt-6 h-[70px] "
                      alt=""
                    />
                    <h3 className="text-[30px] leading-[35px]  text-[#1A202C] font-extrabold mb-[6px]">
                      No Department Found
                    </h3>
                  </td>
                </tr>
              )}

              {DepartmentQuery?.data?.data?.map((result) => (
                <tr className="mb-2 hover:bg-light-gray">
                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {result?.name}
                  </td>

                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {formatDate(result?.created_at)}
                  </td>

                  <td className="whitespace-nowrap py-[16px] bg-white  px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    <Menu>
                      <MenuButton bg={"none"} _hover={"none"}>
                        <button
                          //onClick={() => handleTransacModalOpen(result)}
                          className="   rounded-sm flex justify-center items-center  hover:bg-[#CBD5E0]  "
                        >
                          <More variant="Linear" color="#98A2B3" size="24" />{" "}
                        </button>
                      </MenuButton>
                      <MenuList maxW="32" className="">
                        <MenuItem
                          onClick={() => handleSyncProduct(result)}
                          w="full"
                          color="#bf0d0d"
                          mb="10px"
                        >
                          <Link
                            variant="Linear"
                            color="#98A2B3"
                            size="16"
                            className="mr-2"
                          />{" "}
                          <p className="text-[12px] md:text-[14px] text-[#475367]  font-normal leading-[18px] md:leading-[20px]">
                            Link product to department
                          </p>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleOpenDelete(result);
                          }}
                          w="full"
                          color="#475367"
                          mb="10px"
                        >
                          <Trash
                            variant="Linear"
                            color="#98A2B3"
                            size="16"
                            className="mr-2"
                          />{" "}
                          <p className="text-[12px] md:text-[14px] text-[#475367]  font-normal leading-[18px] md:leading-[20px]">
                            Delet department
                          </p>
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="flex items-center justify-between mt-5">
        <button
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
        >
          Previous
        </button>

        <button
          disabled={DepartmentQuery?.data?.data?.length < 10}
          className="border-[0.8px] border-[#E4E7EC] py-2 px-3 text-center w-[90px] text-sm"
          onClick={() => setPage((prev) => prev + 1)}
        >
          Next
        </button>
      </div>

      <Modal
        isCentered
        isOpen={isSyncProduct}
        onClose={closeSyncProduct}
        size={{ base: 'xs', sm: 'md', lg: 'xl' }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
          >
          Add Product To Department
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <Divider color="#98A2B3" />
          <ModalBody
            pt={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            pb={{ base: "30px", md: "40px" }}
            className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <div className="mb-[18px]">
              <label className="text-[14px] text-[#667185] leading-[20px]   mb-[8px] ">
                Department Name
              </label>
              <div className=" relative    flex banks-center">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[48px] pl-[24px] pr-[8px] py-[12px] text-[14px] text-[#344054] leading-[20px]  placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D0D5DD] border-[0.2px] rounded-[8px] focus:outline-none focus:ring-[#185392] focus:border-[#185392] "
                  name="name"
                  id="full-name"
                  value={department?.name}
                  // onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>

            <div className="relative w-full">
              {/* Input Field */}
              <div
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="border border-gray-300 p-2 rounde-lg cursor-pointer flex flex-wrap gap-1"
              >
                {selectedItems.map((item, index) => (
                  <span
                    key={index}
                    className="bg-[#185392]  text-white px-2 py-1 rounded flex items-center gap-1"
                  >
                    {ProductQuery?.data &&
                      ProductQuery?.data?.data.map(
                        (product, index) =>
                          item === product?.product_id && (
                            <span key={index}>{product?.name}</span>
                          )
                      )}
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        removeItem(item);
                      }}
                      className="text-xs ml-1 text-white hover:text-red-500"
                    >
                      ×
                    </button>
                  </span>
                ))}
                <input
                  type="text"
                  className="flex-grow border-none focus:ring-0 focus:outline-none"
                  placeholder={
                    selectedItems.length === 0 ? "Select items..." : ""
                  }
                  readOnly
                />
              </div>

              {/* Dropdown List */}
              {dropdownOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-md max-h-60 overflow-y-auto z-10">
                  {ProductQuery?.data?.data.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => toggleSelectItem(item?.product_id)}
                      className={`p-2 cursor-pointer hover:bg-[#185392c9]  hover:text-white ${
                        selectedItems.includes(item?.product_id)
                          ? "bg-gray-300  text-black"
                          : ""
                      }`}
                    >
                      {item?.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </ModalBody>
          <Divider />
          <ModalFooter gap={"16px"}>
            <button
              onClick={closeSyncProduct}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
              onClick={syncProduct}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex banks-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Update </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        isCentered
        isOpen={isDeleteOpen}
        onClose={HandleDeleteModalClose}
       size={{ base: 'xs', sm: 'md', lg: 'xl' }}
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay bg="blackAlpha.300" />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px]   font-medium leading-[24px] md:leading-[24px]"
          >
            <Trash
              color="#C90808FF"
              size={36}
              className="text-center mx-auto"
            />
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <ModalBody
            py={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            className=" px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <p className=" text-[16px] md:text-lg text-center  text-[#000] leading-[24px] font-medium  ">
              Delete Department
            </p>

            <p className="text-[14px]  text-[#667185] leading-[20px] font-normal text-center mt-2  ">
              Are you sure you want to delete this department? This action
              cannot be undone.
            </p>
          </ModalBody>
          <ModalFooter gap={"16px"}>
            <button
              onClick={HandleDeleteModalClose}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
              onClick={deleteDepartment}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#185392] flex items-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Delete </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ModalLeft isOpen={isCreateDepartment} onClose={closeCreateDepartment}>
        <div>
          <div className="border-b border-b-[#E4E7EC] p-[16px] md:p-[20px]  md:flex justify-between items-center ">
            <div className="flex items-center gap-[16px]">
              <Maximize4 variant="Linear" color="#667185" size="16" />{" "}
              <div className="h-[32px] w-[1px] bg-[#D0D5DD]" />
              <div className="flex items-center">
                <p className="text-[#667185] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                  Create Sub-User
                </p>
              </div>
            </div>
          </div>

          <form
            onSubmit={createDepartment}
            className=" w-full mt-[20px] px-3 md:px-6"
          >
            <div className="mb-[24px]">
              <label className="text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Name
              </label>
              <div className=" ">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[50px] px-[16px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  required
                  autoComplete="on"
                  name="name"
                  value={formValue.name}
                  onChange={(e) => handleInputChange(e)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full py-[14px] text-center text-white bg-[#124072]  flex items-center justify-center mb-[20px] md:mb-[32px]"
            >
              <p className="text-sm font-medium leading-[20px]">
                Create Department{" "}
              </p>{" "}
              {isLoading && (
                <ClipLoader color={"white"} size={20} className="ml-2" />
              )}
            </button>
          </form>
        </div>
      </ModalLeft>

      <ModalLeft isOpen={isViewModal} onClose={closeViewModal}>
        <div>
          <div className="border-b border-b-[#E4E7EC] p-[16px] md:p-[20px]  md:flex justify-between items-center ">
            <div className="flex items-center gap-[16px]">
              <Maximize4 variant="Linear" color="#667185" size="16" />{" "}
              <div className="h-[32px] w-[1px] bg-[#D0D5DD]" />
              <div className="flex items-center">
                <p className="text-[#667185] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                  User Details
                </p>
              </div>
            </div>
          </div>

          <div class="relative w-[60px] h-[60px] overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 mx-auto mt-3">
            <svg
              class="absolute w-[68px] h-[68px] text-gray-400 -left-1"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      </ModalLeft>
    </div>
  );
};

export default Department;
