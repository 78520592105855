import React, { useRef } from "react";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  Thead,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ClipLoader } from "react-spinners";
import OTPInput from "otp-input-react";
import { NumericFormat } from "react-number-format";

const PreviewModal = ({
  isLoading,
  invoiceItems,

  handlePayment,
  onClose,
  merchant,
  amount,
  product,

  note,
}) => {
  function getFormattedCurrentDay(format = "full") {
    const date = new Date();
    const options = {
      full: { weekday: "long", year: "numeric", month: "long", day: "numeric" },
      short: { weekday: "short", month: "short", day: "numeric" },
      weekday: { weekday: "long" },
      compact: { weekday: "short" },
    };

    return date.toLocaleDateString("en-US", options[format]);
  }
  return (
    <>
      {" "}
      <ModalHeader
        py="4"
        color="#000000"
        className="text-[18px] md:text-[20px] text-[#000000] font-medium leading-[24px] md:leading-[24px]"
      >
        Preview Product Details
      </ModalHeader>
      <ModalCloseButton size={"sm"} />
      <Divider color="#98A2B3" />
      <ModalBody
        pt={{ base: "20px", md: "24px" }}
        px={{ base: "16px", md: "24px" }}
        pb={{ base: "30px", md: "40px" }}
        className="pt-[20px] md:pt-[24px] px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
      >
        <div className="px-[10px] py-[18px] rounded-lg bg-slate-100 text-[#667185] w-full">
          <div className="">
            {" "}
            <NumericFormat
              value={amount}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"₦"}
              decimalScale={2}
              fixedDecimalScale={true}
              renderText={(value) => (
                <p className="text-[#667185] font-semibold font-i_medium text-[24px] leading-[26px] text-center  tracking-[0.2px]   ">
                  {value}
                </p>
              )}
            />
          </div>

          <ul className="gap-[8px] flex flex-col mt-4">
            {merchant && (
              <li className="flex justify-between items-center ">
                <p className="text-[14px] leading-[14px] ">Merchant:</p>
                <p className="text-[14px] leading-[14px] font-medium">{merchant}</p>
              </li>
            )}
                         <div className="overflow-x-auto rounded-lg">
            <table className="min-w-full mb-6 border-[0.8px] border-r-[0.8px]  border-l-[0.8px] border-[#E4E7EC] rounded-lg">
              <thead className="bg-[#F9FAFB]">
                <tr className="">
                  <th
                    scope="col"
                    className="  border-b-[0.8px] border-[#E4E7EC] py-[8px] px-5  gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                  >
                      Item
                  </th>
                  <th
                    scope="col"
                    className="  border-b-[0.8px] border-[#E4E7EC] py-[8px] md:py-[12px] px-3 md:px-5  gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                  >
                      Qty
                  </th>

                
                  <th
                    scope="col"
                    className="  border-b-[0.8px] border-[#E4E7EC] py-[8px] md:py-[12px] px-3 md:px-5  gap-[6px] md:gap-[12px] text-[14px] md:text-[16px] text-[#98A2B3]  font-medium leading-[20px] md:leading-[24px] tracking-[0.2%]"
                  >
                      Amount ₦
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceItems.map((item, index) => (
                  <tr key="_" className="mb-2 hover:bg-light-gray">
                    <td className="whitespace-nowrap py-[10px] md:py-[16px] bg-white px-3  md:px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      {item.name}
                    </td>
                    <td className="whitespace-nowrap py-[10px]  md:py-[16px] bg-white   border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                    {item.quantity}
                    </td>
                   
                    <td className="whitespace-nowrap py-[10px] md:py-[16px] bg-white px-3  md:px-5  border-b-[0.8px] border-[#E4E7EC] text-[14px] leading-[24px] tracking-[0.2px] text-[#667185] font-medium text-left  ">
                      <NumericFormat
                        value={item.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"₦"}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={(value) => <p>{value}</p>}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          
            {amount && (
              <li className="flex justify-between items-center ">
                <p className="text-[14px] leading-[14px] ">Total:</p>
                <p className="text-[14px] leading-[14px] font-medium">
                  {" "}
                  <NumericFormat
                    value={amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₦"}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => (
                      <p className="text-[14px] leading-[14px] font-medium">
                        {" "}
                        {value}
                      </p>
                    )}
                  />
                </p>
              </li>
            )}

            {note && (
              <li className="flex justify-between items-center ">
                <p className="text-[14px] leading-[14px] ">Note:</p>
                <p className="text-[14px] leading-[14px] font-medium">
                  {note}
                </p>
              </li>
            )}
           

            <li className="flex justify-between items-center ">
              <p className="text-[14px] leading-[14px] ">Date:</p>
              <p className="text-[14px] leading-[14px] font-medium">
                {getFormattedCurrentDay("short")}
              </p>
            </li>

           
          </ul>
          
        </div>
      </ModalBody>
      <Divider />
      <ModalFooter gap={"16px"}>
        <button
          onClick={onClose}
          className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px]  py-[8px] md:py-[12px] text-[14px] font-medium text-black"
        >
          Cancel
        </button>
        <button
          onClick={handlePayment}
          disabled={isLoading}
          className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex banks-center justify-center text-center rounded-[8px] py-[8px] md:py-[12px] text-[14px] font-medium text-white"
        >
          {isLoading ? (
            <ClipLoader color={"white"} size={20} />
          ) : (
            <> Confirm </>
          )}
        </button>
      </ModalFooter>
    </>
  );
};

export default PreviewModal;
