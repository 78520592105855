import React, { useState } from "react";
import Modal from "../../component.js/Modal";

import { useQuery } from "@tanstack/react-query";
import { ProfileCircle, Trash } from "iconsax-react";
import api from "../../api";
import ImageUpload from "../../component.js/UploadImage";
import { BASE_URL } from "../../utils/config";
import { enqueueSnackbar } from "notistack";
import { ClipLoader } from "react-spinners";

const MyProfile = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [nextOfKin, setNextOfKin] = useState([
    {
      id: "0",
      name: "",
      relationship: "",
      phone: "",
    },
  ]);
  const [occupation, setOccupation] = useState("");

  const handleProfileModal = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  const handlekinemic = () => {
    setNextOfKin([
      ...nextOfKin,
      {
        id: JSON.stringify(nextOfKin?.length + 1),
        name: "",
        relationship: "",
        phone: "",
      },
    ]);
  };
  const handleKinChange = (index, event) => {
    const { name, value } = event.target;
    const newKin = [...nextOfKin];
    newKin[index][name] = value;
    setNextOfKin(newKin);
  };

  const removeItem = (idToRemove) => {
    const updatedItems = nextOfKin.filter((item) => item.id !== idToRemove);
    setNextOfKin(updatedItems); // Update state with the new array
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  async function handleProfile(e) {
    setIsLoading(true);

    try {
      const response = await api.updateProfile({
        date_of_birth: dob,
        address,
        next_of_kin: nextOfKin,
        occupation,
      });

      enqueueSnackbar(response.message[0], { variant: "success" });
      setIsLoading(false);
      handleProfileModal();
    } catch (e) {
      setIsLoading(false);

      enqueueSnackbar(e.message, { variant: "error" });
    }
  }

  return (
    <div>
      <div className="border-t border-b border-[#D6DDEB] p-[18px] md:p-[24px] xl:p-[32px]">
        <div className="px-[16px] md:px-[32px] xl:px-[43px] py-[24px] md:py-[42px] xl:py-[70px] flex flex-wrap items-center gap-[16px] md:gap-[24px] xl:gap-[32px] bg-[#F8F8FD] ">
          {profileData?.image ? (
            <img
              src={`
${BASE_URL}${profileData?.image}`}
              alt="profile"
              className="h-[60px] w-[60px] md:h-[80px] md:w-[80px] xl:h-[100px] xl:w-[100px] rounded-full "
            />
          ) : (
            <ProfileCircle size="60" color="#7e828b" variant="Bulk" />
          )}

          <div className="flex-1">
            <h3 className="text-[18px] md:text-[24px] xl:text-[28px] mb-1 md:mb-2 font-medium text-[#25324B]">
              {profileData?.name}
            </h3>
            <p className="text-sx md:text-sm xl:text-lg text-[#515B6F] ">
              {profileData?.email}
            </p>
          </div>

          <button
            onClick={() => setIsOpen(true)}
            className="w-[110px] md:w-[160px] xl:w-[200px] rounded-lg py-[10px] md:py-[7px] bg-[#25324B] text-center "
          >
            <p className="text-white text-[12px] md:text-sm  ">Change Photo</p>
          </button>
        </div>
      </div>
      <div className="p-[18px] md:p-[24px] xl:p-[32px] grid grid-cols-1  gap-[20px]">
        <ul>
          {profileData?.other_details?.merchant_details && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Business Name
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.other_details?.merchant_details?.business_name}
              </p>
            </li>
          )}
          {profileData?.other_details?.merchant_details && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Business Description
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.other_details?.merchant_details?.business_type}
              </p>
            </li>
          )}

          <li className="mb-1">
            <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Full Name
            </p>
            <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.name}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Email Address
            </p>
            <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.email}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Phone Number
            </p>
            <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.phone}
            </p>
          </li>
          <li className="mb-1">
            <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
              Notification Mail
            </p>
            <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
              {profileData?.mail_notification}
            </p>
          </li>

          {profileData?.date_of_birth && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Date Of Birth
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.date_of_birth}
              </p>
            </li>
          )}

          {profileData?.profile?.date_of_birth && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Date Of Birth
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.profile?.date_of_birth}
              </p>
            </li>
          )}
          {profileData?.profile?.address && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Address
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.profile?.address}
              </p>
            </li>
          )}
          {profileData?.profile?.occupation && (
            <li className="mb-1">
              <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Occupation
              </p>
              <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                {profileData?.profile?.occupation}
              </p>
            </li>
          )}

          {profileData?.profile?.next_of_kin && (
            <li className="mb-1">
              <p className="text-base md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                Next of Kin(s)
              </p>
             

              {profileData?.profile?.next_of_kin?.map((item, index) => (
                <div>
                  <div className="mb-2">
                  <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                    Name
                  </p>
                  <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                    {item?.name}
                  </p>
                  </div>
                  <div className="mb-2">
                  <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                    Relationship
                  </p>
                  <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                    {item?.relationship}
                  </p>
                  </div>
                  <div className="mb-2">
                  <p className="text-sm md:text-[18px] xl:text-[20px] font-medium mb-1 text-[#25324B]">
                    Phone Number (Next of Kin)
                  </p>
                  <p className="text-sx md:text-[16px]  font-normal  text-[#515B6F]">
                    {item?.phone}
                  </p>
                  </div>
                 
                </div>
              ))}
            </li>
          )}
        </ul>

        <button
          onClick={() => handleProfileModal()}
          className="w-full rounded-lg py-[10px] md:py-[7px] mt-5 bg-[#25324B] text-center "
        >
          <p className="text-white text-sx md:text-lg  ">Update Profile</p>
        </button>
      </div>

      <Modal isOpen={isOpen} onClose={handleCloseModal}>
        <div className="inline-block relative  p-[18px] md:p-[24px] xl:p-[32px] overflow-hidden text-left align-bottom transition-all transform bg-[white] rounded-lg   shadow-xl sm:my-8 sm:align-middle w-full min-w-[360px] md:min-w-[450px] md:max-w-[750px] ">
          <h2 className="text-[20px] md:text-[24px] xl:text-[28px] text-center font-normal leading-[35px] text-black mb-[16px] md:mb-[20px] ">
            Update Profile Image
          </h2>
          <div className="flex justify-center">
            <ImageUpload
              handleCloseModal={handleCloseModal}
              refetch={ProfileQuery.refetch()}
            />
          </div>
        </div>
      </Modal>

      <Modal isOpen={isProfileOpen} onClose={handleProfileModal}>
        <div className="inline-block relative  p-[18px] md:p-[24px] xl:p-[32px] overflow-hidden text-left align-bottom transition-all transform bg-[white] rounded-lg   shadow-xl sm:my-8 sm:align-middle w-full min-w-[360px] md:min-w-[450px] md:max-w-[750px] ">
          <h2 className="text-[20px] md:text-[24px] xl:text-[28px] text-center font-normal leading-[35px] text-black mb-[16px] md:mb-[20px] ">
            Update Profile Details
          </h2>
          <div>
            <div className="mb-[18px]">
              <label className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Date Of Birth
              </label>
              <div className=" ">
                <input
                  type="date"
                  placeholder=""
                  className="w-full h-[40px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  value={dob}
                  onChange={(e) => setDob(e.target.value)}
                  autoCapitalize="off"
                  autoCorrect="off"
                  spellCheck="false"
                />
              </div>
            </div>
            <div className="mb-[18px]">
              <label className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Address
              </label>
              <div className=" ">
                <textarea
                  type="text"
                  placeholder=""
                  className="w-full h-[80px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-[18px]">
              <label className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                Occupation
              </label>
              <div className=" ">
                <input
                  type="text"
                  placeholder=""
                  className="w-full h-[40px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label className="text-[14px] md:text-[14px]  font-medium leading-[24px] text-[#515B6F] mb-[8px]">
                Next of Kin
              </label>
              <div className="col-lg-6 pe-">
                {nextOfKin?.map((kin, index) => (
                  <>
                    <div class="form-group mt-4">
                      <label
                        for="exampleFormControlSelect1"
                        className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px] flex justify-between"
                      >
                        Next of Kin Name
                        {kin.id > 0 && (
                          <button onClick={() => removeItem(kin.id)}>
                            <Trash color="red" size={15} />
                          </button>
                        )}
                      </label>
                      <input
                        type="text"
                        placeholder=""
                        className="w-full h-[40px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                        required
                        name="name"
                        value={kin?.name}
                        onChange={(event) => handleKinChange(index, event)}
                      />
                    </div>
                    <div class="form-group">
                      <label
                        for="exampleFormControlSelect1"
                        className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px]"
                      >
                        Relationship
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                        placeholder=""
                        name="relationship"
                        value={kin.relationship}
                        onChange={(event) => handleKinChange(index, event)}
                      />
                    </div>

                    <div class="form-group">
                      <label className="text-[14px] md:text-[14px]  font-normal leading-[24px] text-[#515B6F] mb-[8px]">
                        Phone Number
                      </label>
                      <input
                        type="text"
                        className="w-full h-[40px] px-[12px] py-[12px] text-[14px] text-[#344054] leading-[20px] bg-[#F7F9FC] placeholder:text-[#98A2B3] placeholder:text-[12px]  border-[#D6DDEB] border-[1px]  focus:outline-none focus:ring-[#124072] focus:border-[#124072] "
                        placeholder=""
                        name="phone"
                        value={kin.phone}
                        onChange={(event) => handleKinChange(index, event)}
                      />
                    </div>
                  </>
                ))}

                <div className="flex justify-end w-full">
                  <button
                    type="button"
                    className="text-sm py-1 px-4 mt-4  mb-2 text-white rounded-md"
                    style={{ backgroundColor: "#25324B" }}
                    onClick={handlekinemic}
                  >
                    Add More
                  </button>
                </div>
              </div>
            </div>

            <button
              onClick={() => handleProfile()}
              className="w-full rounded-lg py-[10px] mt-5 md:py-[7px] bg-[#25324B] text-center flex justify-center items-center gap-2"
            >
              <p className="text-white text-sx md:text-lg  ">Update Profile</p>{" "}
              {isLoading && (
                <ClipLoader color={"white"} size={20} className="ml-2" />
              )}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyProfile;
