import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Grid,
  Flex,
  Button,
  Divider,
  Modal,
  Thead,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Switch,
} from "@chakra-ui/react";
// import { useQuery } from "@tanstack/react-query";
import { IoMdInformationCircleOutline } from "react-icons/io";
import {
  ArrangeHorizontal,
  ArrowDown3,
  EmptyWalletChange,
  Logout,
  Profile,
  ProfileCircle,
} from "iconsax-react";
import { useQuery } from "@tanstack/react-query";
import api from "../api";
import { ClipLoader } from "react-spinners";
import { enqueueSnackbar } from "notistack";
import { BASE_URL } from "../utils/config";
// import {IoMdInformationCircleOutline} from "react-icons/io"

const Topbar = ({ setIsSidebar, userData }) => {
  const [logo, setLogo] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSwitchModal, setIsSwitchModal] = useState(false);
  const [switchId, setSwitchId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  let role = JSON.parse(userData);
  role = role?.user?.role;
  const handleIsModalClose = () => {
    setIsModalOpen(false);
  };
  const handleSwitchModal = (partner) => {
    setIsSwitchModal(!isSwitchModal);
    setSwitchId(partner);
  };

  const closeSwitchModal = () => {
    setIsSwitchModal(false);
    setSwitchId("");
  };

  async function getProfile(page) {
    const response = await api.getProfile();
    return response;
  }

  async function getMerchants(page) {
    const response = await api.getMyMerchants({ params: { page } });
    return response;
  }

  const ProfileQuery = useQuery(["profile"], () => getProfile(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const profileData = ProfileQuery?.data?.data || "";

  const MerchantsQuery = useQuery(["merchants"], () => getMerchants(), {
    keepPreviousData: true,
    refetchOnWindowFocus: "always",
  });

  const merchantData = MerchantsQuery?.data?.data || "";

  function firstAndLastLetter(word) {
    if (word && word?.length < 1) {
      return "Input word is too short!";
    }
    const Word = word ? word : "";

    const firstLetter = Word[0]; // First letter
    const lastLetter = Word[Word.length - 1]; // Last letter

    return `${firstLetter}`;
  }



  const switchBusiness = async () => {
    setIsLoading(true);

    try {
      const response = await api.switchMerchant({
        merchant_id: switchId?.merchant_id,
      });

      enqueueSnackbar(response.message, { variant: "success" });
      setIsLoading(false);
      window.location.reload();
      setIsSwitchModal(false);
    } catch (e) {
      enqueueSnackbar("error switching business", { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <div className="flex w-full items-center justify-between px-6 gap-[16px] py-3 border-b border-[#7C8493]">
      <div className="flex items-center">
        <button
          class="h-7 w-7 bg-[#124072] px-2 py-2 rounded-lg lg:hidden mr-2 flex justify-center items-center"
          onClick={setIsSidebar}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 12H20"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4 18H20"
              stroke="#fff"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
        <h4 className="text-[24px] text-[#1a202c] font-bold hidden md:block">
          {location.pathname === "/dashboard"
            ? "Dashboard"
            : location.pathname === "/notification"
            ? "Notification"
            : location.pathname === "/grants"
            ? "Award/Grants"
            : location.pathname === "/myapplication"
            ? "Applications"
            : location.pathname === "/myprofile"
            ? "Profile"
            : location.pathname === "/settings"
            ? "Settings"
            : location.pathname === "/invoice"
            ? "Invoice"
            : location.pathname === "/register"
            ? "Award Registration"
            : location.pathname === "/userAdmin"
            ? "User Admin"
            : location.pathname === "/isActive"
            ? "IsActive"
            : location.pathname === "/accountDetails"
            ? "Account Details"
            : location.pathname === "/withdraw"
            ? "Withdrawal"
            : location.pathname === "/currency"
            ? "Currency"
            : ""}
        </h4>{" "}
      </div>
      <div className="flex items-center  gap-3">
        <Menu>
          <MenuButton bg={"none"}>
            <div className="flex items-center  gap-3  rounded-lg border-[#fefefe] py-1 px-2">
              <div className="flex items-center gap-2">
                {profileData?.image ? (
                  <img
                    src={`
${BASE_URL}${profileData?.image}`}
                    alt="profile"
                    className="h-[30px] w-[30px] md:h-[35px] md:w-[35px] rounded-full "
                  />
                ) : (
                  <ProfileCircle size="35" color="#7e828b" variant="Bulk" />
                )}
                <p className="text-black font-semibold"> {profileData?.name}</p>
              </div>

              <button>
                <ArrowDown3 variant="Bold" size="14" color="#000" />
              </button>
            </div>
          </MenuButton>
          <MenuList
            maxW="240px"
            w="240px"
            className="border-[2px] p-[10px] md:p-[16px]"
          >
            <div className="flex items-center gap-[16px]">
              <div className="h-[28px] w-[28px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#124072] flex justify-center items-center">
                {" "}
                <p className="text-[#fff] text-[12px] md:text-[12px] xl:text-[12px] font-bold leading-[24px] ">
                  {firstAndLastLetter(profileData?.name)}
                </p>
              </div>
              <p className="text-[#000000] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                {profileData?.name}
              </p>
            </div>
            <div className="mt-3">
              <Link
                // onClick={() => setIsSettingsModal(true)}
                to="/profile"
                className="py-[6px] px-[12px] border-[0.2px] flex items-center bg-[#124072]  rounded-md border-[#98A2B3] w-full flex-item gap-2"
              >
                <Profile size={16} color="#fff" />{" "}
                <p className="text-[#fff] text-[12px]  xl:text-[12px] font-normal leading-[18px] ">
                  Profile
                </p>
              </Link>
            </div>
            {role === "merchant" ? (
              ""
            ) : (
              <>
                {merchantData &&
                  merchantData?.filter(
                    (result) =>
                      //console.log(ProfileQuery?.data?.default_partner?.id)

                      ![result?.merchant_id.toString()].includes(
                        profileData?.balance?.merchant_id
                      )
                  ).length > 0 && (
                    <div>
                      <p className="text-[#000] text-[12px]  xl:text-[12px] font-semibold mt-3 leading-[18px] mb-3 ">
                        Switch Business
                      </p>
                    </div>
                  )}

                {merchantData &&
                  merchantData
                    ?.filter(
                      (result) =>
                        //console.log(ProfileQuery?.data?.default_partner?.id)

                        ![result?.merchant_id.toString()].includes(
                          profileData?.balance?.merchant_id
                        )
                    )
                    .map((partner) => (
                      <button
                        className="flex justify-between items-center w-full hover:bg-slate-50 rounded-lg mb-2"
                        key={partner.id}
                        onClick={() => handleSwitchModal(partner)}
                      >
                        <div className="flex items-center gap-2">
                          <div className="h-[28px] w-[28px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#d3d3d4] flex justify-center items-center">
                            {" "}
                            <p className="text-[#475367] text-[12px] md:text-[12px] xl:text-[12px] font-bold leading-[24px] ">
                              {firstAndLastLetter(partner?.business_name)}
                            </p>
                          </div>
                          <p className="text-[#000000] text-[14px] md:text-[14px] xl:text-[16px] font-normal leading-[24px] ">
                            {partner?.business_name}
                          </p>
                        </div>
                      </button>
                    ))}
              </>
            )}

            <Link to="www.google.com" rel="noreferrer" target="_blank"  className="underline mb-2 text-[#124072] text-xs">click!</Link>
            <div className="py-[16px] border-t-[0.2px]  border-[#98A2B3] mt-[20px] ">
              <button
                onClick={() => {
                  // api.logout();
                  navigate("/login");
                }}
                className="flex gap-2 mt-[20px]"
              >
                {" "}
                <Logout size={20} color="#124072 " />{" "}
                <p className="text-[#124072 ] text-[12px]  xl:text-[14px] font-normal leading-[18px] ">
                  Log out
                </p>
              </button>
            </div>
          </MenuList>
        </Menu>
      </div>

      <Modal
        isCentered
        isOpen={isSwitchModal}
        onClose={closeSwitchModal}
        size="md"
        style={{ borderRadius: 12 }}
        motionPreset="slideInBottom"
        className="rounded-[12px]"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            py="4"
            color="#000000"
            className="text-[18px]   font-medium leading-[24px] md:leading-[24px]"
          >
            <EmptyWalletChange
              size={32}
              variant="Bold"
              color="#124072"
              className="mx-auto text-center"
            />
          </ModalHeader>
          <ModalCloseButton size={"sm"} />
          <ModalBody
            py={{ base: "20px", md: "24px" }}
            px={{ base: "16px", md: "24px" }}
            className=" px-[16px] md:px-[24px] pb-[30px] md:pb-[40px]"
          >
            <p className=" text-[16px] md:text-lg text-center  text-[#000] leading-[24px] font-medium  ">
              Switch Business
            </p>

            <p className="text-[14px]  text-[#667185] leading-[20px] font-normal text-center mt-2  ">
              Are you sure you want to switch bussiness
            </p>

            <div className="flex items-center justify-center space-x-5 mt-5">
              <div className="flex-item gap-2">
                <div className="h-[28px] w-[28px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#d3d3d4] flex justify-center items-center">
                  {" "}
                  <p className="text-[#475367] text-[12px] md:text-[16px] xl:text-[16px] font-bold leading-[24px] ">
                    {firstAndLastLetter(
                      profileData?.balance?.merchant_info?.business_name
                    )}
                  </p>
                </div>
                <p className="text-[#000000] text-[14px] md:text-[14px] xl:text-[18px] font-bold leading-[24px] ">
                  {profileData?.balance?.merchant_info?.business_name}
                </p>
              </div>

              <ArrangeHorizontal size={24} color="#124072" />
              <div className="flex-item gap-2">
                <div className="h-[28px] w-[28px] md:h-[32px] md:w-[32px] rounded-[4px] bg-[#d3d3d4] flex justify-center items-center">
                  {" "}
                  <p className="text-[#475367] text-[12px] md:text-[16px] xl:text-[16px] font-bold leading-[24px] ">
                    {firstAndLastLetter(switchId?.business_name)}
                  </p>
                </div>
                <p className="text-[#000000] text-[14px] md:text-[14px] xl:text-[18px] font-bold leading-[24px] ">
                  {switchId?.business_name}
                </p>
              </div>
            </div>
          </ModalBody>
          <ModalFooter gap={"16px"}>
            <button
              onClick={closeSwitchModal}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] text-center rounded-[8px] py-[12px] text-[14px] font-medium text-black"
            >
              Cancel
            </button>
            <button
              onClick={switchBusiness}
              className="border-[0.2px]  border-[#98A2B3] w-[99px] bg-[#124072] flex items-center justify-center text-center rounded-[8px] py-[12px] text-[14px] font-medium text-white"
            >
              {isLoading ? (
                <ClipLoader color={"white"} size={20} />
              ) : (
                <> Switch </>
              )}
            </button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Topbar;
